import React, { useEffect } from "react";
import { Link as ReachLink } from "gatsby";
import { Stack, Flex, Row, Col, Grid, Box, Hide } from "pws-design-system/design-system";
import NavItem from "../NavItem";
import PageLayout from "../../../common/layouts/PageLayout";
import _startcase from "lodash.startcase";
import UnitToggle from "../../../common/components/unit-toggle/";
import SiteHeader from "../SiteHeader";
import MobileNavDropdown from "./mobile-nav-dropdown";
import Ad from "../../../common/components/ad";
import { isBrowser } from "../../../../utils/";
import { ThemeContainer } from "../../../common/hooks/useTheme";

type DashboardLayoutProps = any & {};

export function PlaceOuterLayout({ children }: DashboardLayoutProps): React.ReactElement {
  return (
    <PageLayout
      contentProps={{
        bg: "brand.gray.100"
      }}
    >
      <Box minHeight="100vh" px={[2, 2, 2, 0]}>
        <Grid>{children}</Grid>
      </Box>
    </PageLayout>
  );
}

const PlaceContent = props => {
  const { theme } = ThemeContainer.useContainer();
  const { children, path, manifest, place, metaTitle, metaDescription } = props;
  const blob = new Blob([JSON.stringify(manifest)], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  const chatLink = `https://demos.xweather.com/data-maps/examples/nimbus/weather-station-chat/?station=${place.placeId}`;
  return (
    <PageLayout
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      manifest={manifestURL}
      headerProps={{
        bg: theme.components.dashboard.header.bg,
        context: "dashboard"
      }}
      contentProps={{
        bg: theme.components.dashboard.content.bg
      }}
    >
      <Box minHeight="100vh" pb={[0, null, 3, 4]}>
        <Grid>
          <Row>
            <Col>
              <SiteHeader place={place} type={path.type} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Hide mobile>
                <Box
                  bg={theme.components.dashboard.header.horizontalRule.color}
                  height="2px"
                  my={2}
                />
                <Flex my={4} align="center" justify="space-between">
                  <Stack spacing={1} isInline>
                    {Object.keys(path.urls)
                      .filter(key => key !== "historical")
                      .map((key: Views) => {
                        return (
                          <NavItem
                            key={path.urls[key]}
                            as={ReachLink}
                            to={path.urls[key]}
                            variant={key === path.view ? "solid" : "ghost"}
                            variantColor={
                              key === path.view
                                ? theme.components.dashboard.header.navItem.selectedVariantColor
                                : theme.components.dashboard.header.navItem.variantColor
                            }
                            pt={"11px"}
                          >
                            {_startcase(key)}
                          </NavItem>
                        );
                      })}
                    <NavItem
                      variant={"ghost"}
                      variantColor={theme.components.dashboard.header.navItem.variantColor}
                      pt={"11px"}
                    >
                      <a href={chatLink} target="_blank">
                        AI Chat
                      </a>
                    </NavItem>
                  </Stack>
                  <UnitToggle
                    showSettings={true}
                    context="components.dashboard.header.unitToggle"
                  ></UnitToggle>
                </Flex>
              </Hide>
            </Col>
          </Row>
          <Hide tablet desktop mb={4}>
            <MobileNavDropdown path={path} />
          </Hide>
          <Row>{children}</Row>
          <Col lg={12}>
            <Ad path={path} id={process.env.GATSBY_AD_ID_DASHBOARD_FOOTER} />
          </Col>
        </Grid>
      </Box>
    </PageLayout>
  );
};
export default function PlaceLayout(props) {
  return <PlaceContent {...props}>{props.children}</PlaceContent>;
}
