import React, { useEffect, useState, useLayoutEffect, useRef, ReactNode } from "react";
import ErrorBoundary from "../error-boundary";
import useUser from "../../../common/hooks/useUser";

const initAd = () => {
  // @ts-ignore
  (window.adsbygoogle = window.adsbygoogle || []).push({});
};

type AdProps = {
  path: string;
  id: string;
  children?: ReactNode;
};

/**
 * Displays a Google Adsense Ad.
 *
 * @param {AdProps} props.
 * @returns {React.ReactElement}
 */
const Ad: React.FC<AdProps> = ({ path, id }): React.ReactElement => {
  const [isLayedOut, setIsLayedOut] = useState(false);
  const [adEl, setAdEl] = useState(null);
  const adRef = useRef(null);

  const user = useUser();

  useLayoutEffect(() => setIsLayedOut(true));

  useEffect(() => {
    if (!isLayedOut) {
      return;
    }

    if (user.hasData && !user.isAuthenticated) {
      if (adRef.current === adEl) {
        return;
      }
      setAdEl(adRef.current);
      initAd();
    }
  }, [path, user, isLayedOut]);

  return (
    <div
      ref={adRef}
      id={`ad-footer-${id}-${path}`}
      style={{ textAlign: "center", width: "100%", minWidth: "320px" }}
      key={`ad-footer-${id}-${path}`}
    >
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-9068509292782968"
        data-ad-slot={id}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  );
};

/**
 * @returns {React.ReactElement}
 */
const WrappedAd: React.FC = (props: AdProps): React.ReactElement => {
  return (
    <ErrorBoundary>
      <Ad {...props} />
    </ErrorBoundary>
  );
};

export default WrappedAd;
